import styled from "styled-components";
import ZigzagBG from "../../images/zigzag-bg.png";

export const ZigzagWrapper = styled.div`
  margin: 0 auto;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const ZigzagInner = styled.div`
  background-image: url(${ZigzagBG});
  width: 313px;
  height: 40px;
`;

import React from "react";
import { ZigzagWrapper, ZigzagInner } from "./zigzag.style";

type ZigzagProps = {};

const Zigzag: React.FunctionComponent<ZigzagProps> = ({ ...props }) => {
  return (
    <ZigzagWrapper>
      <ZigzagInner />
    </ZigzagWrapper>
  );
};

export default Zigzag;
